import {mapMutations, mapGetters, mapActions} from "vuex";
import _ from "lodash";

export default {
    name: "products",
    components: {},
    data() {
        return {}
    },
    watch: {},

    mounted() {


    },
    created() {
    },
    computed: {
        ...mapGetters({
            oneCategoryList: 'catalog/oneCategoryList',
            oneCategoriesIsLoading: 'catalog/oneCategoriesIsLoading',
            attributesValue: 'catalog/attributesValue',
            paginationValue: 'catalog/pagination'
        })
    },
    methods: {
        ...mapMutations({
            skipPage: 'catalog/SKIP_PAGINATION'
        }),
        ...mapActions({
            fetchFilters: 'catalog/GET_FILTERED_PRODUCTS'
        }),
        sendRequest: _.debounce(function () {
            let obj = {}
            obj.data = this.attributesValue.data
            obj.limit = this.paginationValue.per_page
            obj.page = this.paginationValue.page
            obj.price_max = this.attributesValue.price_max
            obj.price_min = this.attributesValue.price_min
            obj.slug = this.attributesValue.slug
            this.fetchFilters(obj)
            window.scrollTo({
                top: 300,
                behavior: "smooth"
            });
        }, 500),
        fetchPagination(item) {
            this.skipPage(item)
            this.sendRequest()
        }

    }
}
