import {mapMutations} from "vuex";
import cloneDeep from 'lodash/cloneDeep';


export const filter = {
    name: "main-filters",
    components: {},
    data() {
        return {
            currentProperty: '',
            collectSlug: []
        }
    },
    watch: {},

    mounted() {
    },
    created() {
    },
    computed: {},
    methods: {

        findDuplicateSlug() {
            let key = this.currentProperty.attributeId;
            let val = this.currentProperty.id.toString();

            let urlQuery = cloneDeep(this.$route.query);
            if (urlQuery[key]) {
                let arrValues = urlQuery[key].toString().split(',');

                let indexVal = arrValues.findIndex((item) => {
                    return item === val
                })
                if (indexVal > -1) {
                    arrValues.splice(indexVal, 1)
                    if (arrValues.length) {
                        urlQuery[key] = arrValues.join(',');
                    } else {
                        delete urlQuery[key];
                    }
                } else {
                    urlQuery[key] = urlQuery[key] + ',' + val;
                }
            } else {
                urlQuery[key] = val;
            }
            this.$router.replace({
                name: 'category',
                params: this.$route.params,
                query: urlQuery
            })
        },
        ...mapMutations({}),
    }
}
